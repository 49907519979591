import { render, staticRenderFns } from "./Blog_input.vue?vue&type=template&id=64936226&scoped=true&"
import script from "./Blog_input.vue?vue&type=script&lang=js&"
export * from "./Blog_input.vue?vue&type=script&lang=js&"
import style0 from "./Blog_input.vue?vue&type=style&index=0&id=64936226&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64936226",
  null
  
)

export default component.exports